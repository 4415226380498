import React, { lazy, FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { Params } from 'utils'

// React code splitting: https://reactjs.org/blog/2018/10/23/react-v-16-6.html
// Webpack magic comments with code splitting: https://webpack.js.org/guides/code-splitting/

const IncidentDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "incident-details" */ 'components/incident-details'
    )
)

export const IncidentDetailsEntry: FunctionComponent = () => {
  const { incidentNumber } = useParams<Params>()
  document.title = `#${incidentNumber} - Splunk On-Call`
  return <IncidentDetails />
}
