import React, { FunctionComponent, memo } from 'react'
import PropTypes from 'prop-types'
import isString from 'lodash/isString'

// Regex adapted from https://www.freecodecamp.org/news/how-to-validate-urls-in-javascript/#heading-how-to-use-regex-to-validate-urls
const urlRegex = new RegExp(
  'https?:\\/\\/' + // validate protocol
    '(([a-z\\d]([a-z\\d-]*[a-z\\d])*\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.,:~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?', // validate fragment locator
  'i'
)

export interface AutoLinkTextProps {
  text: string
}

export const AutoLinkText: FunctionComponent<AutoLinkTextProps> = memo(
  ({ text }) => {
    if (!text || !isString(text)) return null
    const parsedText = []
    let iterText = text
    let match = urlRegex.exec(iterText)
    while (match) {
      const preMatch = iterText.slice(0, match.index)
      if (preMatch) parsedText.push(preMatch)
      parsedText.push(
        <a key={match[0]} href={match[0]} target='_blank' rel='noreferrer'>
          {match[0]}
        </a>
      )
      iterText = iterText.slice(match.index + match[0].length, iterText.length)
      match = urlRegex.exec(iterText)
    }
    if (iterText) parsedText.push(iterText)
    return <>{parsedText}</>
  }
)

AutoLinkText.propTypes = {
  text: PropTypes.string.isRequired,
}
