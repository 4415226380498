import React, { lazy, FunctionComponent } from 'react'
import { Navigate } from 'react-router-dom'
import { translate } from 'utils'
import PropTypes from 'prop-types'

// React code splitting: https://reactjs.org/blog/2018/10/23/react-v-16-6.html
// Webpack magic comments with code splitting: https://webpack.js.org/guides/code-splitting/

const OrganizationalIntelligence = lazy(
  () =>
    import(
      /* webpackChunkName: "organizational-intelligence" */ 'components/organizational-intelligence'
    )
)

export interface OrganizationalIntelligenceEntryProps {
  isUserStakeholder: boolean
  orgSlug: string
}

export const OrganizationalIntelligenceEntry: FunctionComponent<OrganizationalIntelligenceEntryProps> = ({
  isUserStakeholder,
  orgSlug,
}) => {
  if (isUserStakeholder) {
    return <Navigate to={`/${orgSlug}/403`} />
  }
  document.title = translate('VO.OI.Title')
  return <OrganizationalIntelligence />
}

OrganizationalIntelligenceEntry.propTypes = {
  isUserStakeholder: PropTypes.bool.isRequired,
  orgSlug: PropTypes.string.isRequired,
}
