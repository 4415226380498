import React, { FunctionComponent, memo, ReactNode } from 'react'
import PropTypes from 'prop-types'

import { TableRowTitle } from '@victorops/victoria'
import { AlertDetailsTableRow } from '../table-display-styles'

export interface RowProps {
  title: string
  children: ReactNode
}

export const Row: FunctionComponent<RowProps> = memo(({ title, children }) => (
  <AlertDetailsTableRow>
    <TableRowTitle>{title}</TableRowTitle>
    <td>{children}</td>
  </AlertDetailsTableRow>
))

Row.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
