import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { NavBar, SystemMessage } from '@victorops/victoria'
import { TrialBanner } from 'components/common/banners/trial-banner'
import { getOrgslugFromLocation, getLocationHost } from 'utils/window'
import { Loading, Snackbar } from 'components/common'
import { fetchOrgMeta, setIsStakeholderInLocalStorage } from 'utils'
import { State } from 'app/reducer'
import { setOrgMeta } from 'slices'
import { AUTO_PAUSE_WARNING_THRESHOLD } from 'constant-values'
import {
  getBaseRoutes,
  incidentDetailRouteBase,
  organizationalIntelligenceRouteBase,
} from './routes'
import { WebsocketProvider } from './websocket-provider'
import { OrganizationalIntelligenceEntry } from './organizational-intelligence-entry'
import { IncidentDetailsEntry } from './incident-details-entry'

// React code splitting: https://reactjs.org/blog/2018/10/23/react-v-16-6.html
// Webpack magic comments with code splitting: https://webpack.js.org/guides/code-splitting/

const ClientError = lazy(
  () =>
    import(
      /* webpackChunkName: "client-error", webpackPrefetch: true */ 'components/client-error'
    )
)

declare global {
  interface Window {
    initSfdcChat: any
  }
}

export const getCurrentEnv = (hostname: string) => {
  const subdomain = hostname?.split('.')[0]
  switch (subdomain) {
    case 'demostportal':
      return 'demostage'
    case 'devstportal':
      return 'devstage'
    case 'stportal':
    case 'stage':
      return 'stage'
    case 'victorops-sandbox':
    case 'play':
      return 'sandbox'
    default:
      return 'production'
  }
}

export const Entry = () => {
  const orgSlug = getOrgslugFromLocation()
  const dispatch = useDispatch()
  const environment = getCurrentEnv(getLocationHost())
  const authorizedOrgs = useSelector((state: State) => state.authorizedOrgs)
  const email = useSelector((state: State) => state.user.email)
  const fullName = useSelector((state: State) => state.user.fullName)
  const username = useSelector((state: State) => state.user.username)
  const onCall = useSelector((state: State) => state.user.shift.current)
  const nextOnCall = useSelector((state: State) => state.user.shift.next)
  const isUserStakeholder = useSelector(
    (state: State) => state.user.roles.isStakeholder
  )
  const isTrial = useSelector(
    (state: State) => state.currentOrg.billingState === 'trial'
  )
  const isOlly = useSelector((state: State) => state.isOlly)
  const { autoPauseThreshold, activeIncidentCount } = useSelector(
    (state: State) => state.autoPause
  )
  const isPaused = activeIncidentCount >= autoPauseThreshold
  const isNearPause =
    activeIncidentCount >= autoPauseThreshold * AUTO_PAUSE_WARNING_THRESHOLD

  setIsStakeholderInLocalStorage(isUserStakeholder)

  useEffect(() => {
    fetchOrgMeta(orgSlug).then((response) => {
      /* eslint no-underscore-dangle: ["error", { "allow": ["_priv"] }] */
      const chatDisabled = response._priv['feature:disablesupportchat']
      dispatch(setOrgMeta(response))
      let timer: number

      function initChat() {
        const chatFrame: HTMLIFrameElement = document.getElementById(
          'sfdcChat'
        ) as HTMLIFrameElement
        if (chatFrame.contentWindow.initSfdcChat && !chatDisabled) {
          const nameParts = fullName.split(' ')
          chatFrame.contentWindow.initSfdcChat(
            {
              context: {
                organization: {
                  slug: orgSlug,
                },
                user: {
                  firstName: nameParts.shift(),
                  lastName: nameParts.join(' '),
                  primaryEmail: email,
                },
              },
            },
            'timeline'
          )
          clearInterval(timer)
        }
      }

      timer = setInterval(initChat, 3000)
    })
  }, [orgSlug, dispatch, email, fullName])

  return (
    <BrowserRouter basename='/ui'>
      <WebsocketProvider />
      {isTrial && <TrialBanner />}
      {!isOlly ? (
        <NavBar
          activeKey='incidents'
          iconLink={
            isUserStakeholder
              ? `/dash/${orgSlug}#/users/${username}`
              : `/ui/${orgSlug}/incidents`
          }
          routes={isUserStakeholder ? [] : getBaseRoutes(orgSlug)}
          {...{
            authorizedOrgs,
            email,
            environment,
            fullName,
            username,
            usernameDisplay: fullName,
            onCall,
            nextOnCall,
          }}
        />
      ) : null}
      {isNearPause && <SystemMessage isPaused={isPaused} />}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path={organizationalIntelligenceRouteBase}
            element={
              <OrganizationalIntelligenceEntry
                {...{ isUserStakeholder, orgSlug }}
              />
            }
          />
          <Route
            path={incidentDetailRouteBase}
            element={<IncidentDetailsEntry />}
          />
          <Route path='/:orgSlug/403' element={<ClientError status='403' />} />
          <Route path='/:orgSlug/404' element={<ClientError status='404' />} />
          <Route path='*' element={<Navigate to={`/${orgSlug}/404`} />} />
        </Routes>
        <Snackbar />
      </Suspense>
    </BrowserRouter>
  )
}
